
.handlePermissions-btn{
    display: flex;
    gap: 5px;
    background-color: #485f73;
    justify-content: center;
    align-items: center;
    color: white;
    border: none;
    padding: 3px 8px !important;
    span{
        font-size: 14px;
    }
    
}

.document-management-filters{
    display: flex;
    gap: 15px;
    justify-content: center;
    flex-wrap: wrap;
    align-items: flex-end;
    padding: 20px 0 0 0px;
    background-color:rgb(238, 237, 237);
    .ant-collapse-content-box{
        padding: 0px !important;
    }
}


.bnts-docManager{
    display: flex;
    justify-content: space-between;
    padding: 0 20px 0 20px;
    margin-top: 15px;
    align-items: flex-start;
}


.search-nav-bar {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgb(238, 237, 237);
	width: 100%;
	min-height: 60px;
    z-index: 4;
}

.options-search {
	display: flex;
	flex-direction: row;
    align-items: center;
    text-align: left;
	gap: 10px;
    padding: 10px ;
    cursor: pointer;
    border: none;
    background-color: white;
    width: 100%;
}

.options-search-folder {
    font-size: 12px;
	color: rgb(151, 151, 151);
	width: 400px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.options-search-row{
    border-bottom: 1px solid rgb(231, 231, 231);
    width: 100%;

    :hover{
        background-color: rgb(241, 241, 241);
    }
}

.options-search-row:hover .view-doc-search{
    display: block;
}


.options-search-tooltip{
    font-size: 12px;
    background-color: rgb(84, 84, 84);
}

.options-search-file{
    max-width: 400px;
    margin-bottom: 5px;
    a{
        color: black;
    }
}

.options-search-list{
    width: 500px;
    position: absolute;
    top: 55px;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 10px;
    box-shadow: 10px 10px 25px -4px rgb(141, 141, 141);
    z-index: 10;
    min-height: 40px;
    max-height: calc(100vh - (230px));
    overflow-y: scroll;
    overflow-x: hidden
}
.options-search-list-up{
    width: 500px;
    position: absolute;
    top: 185px;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 10px;
    box-shadow: 10px 10px 25px -4px rgb(141, 141, 141);
    z-index: 10;
    min-height: 40px;
    max-height: calc(100vh - (230px));
    overflow-y: scroll;
    overflow-x: hidden
}

.background-docs{
    background-color: rgba(77, 77, 77, 0.5);
    height: 100%;   
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3
}
.background-docs-list{
    background-color: rgba(77, 77, 77, 0.5);
    height: calc(100vh + 80px);   
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3
}

.enter-text-p{
    width: 100%;
    text-align: center;
    padding-top: 12px;
    font-size: 15px;
    color: rgb(168, 168, 168);
    font-style: italic;
}

.search-criteria{
    width: 100%;
    text-align: center;
    padding-top: 8px;
    font-size: 13px;
    color: rgb(58, 58, 58);
    font-style: italic;
}

.hideOverflow{
    overflow: hidden;
}

.view-doc-search{
    color: rgb(119, 119, 119);
    display:none;
}

.ant-collapse-header{
    background-color: rgb(238, 237, 237) !important;
    text-align: center;
}

.selector-col{
	width: 100%;
}

.tags-in-searchNav{
    margin-top: 5px;
}

.search-for-file{
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.filter-opt-container{
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.btns-search{
	display: flex;
	gap: 10px;
	justify-content: flex-start;
	align-items: flex-end;
	margin-top: 10px;
}

.search-nav-bar-collapse {
    .ant-collapse-content-box{
        padding: 0px 0px 5px 0px !important;
    }
}