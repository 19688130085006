.app-selector{
	background-color: rgb(238, 237, 237);
    width: 100%;
    height: calc(100vh - 20px - 40px - 60px);   
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 500px;

    .app-selector-title{
        font-size: 24px;
        margin: 0 280px 5px 0 
    }

    .app-selector-subtitle{
        font-size: 16px;
        margin: 0 470px 30px 0 
    }

    .app-selector-cards{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding-bottom: 6%;
        gap: 30px;
    
        .app-card{
            width: 200px;
            height: 250px;
            color: black;
            background-color: white;
            text-align: left;
            border-radius: 15px;
            box-shadow: 3px 2px 14px 1px rgba(192, 192, 192, 0.37);
            padding: 0 15px 0 15px;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            cursor: pointer;
            border: none;
            transform: scale(1.05);
            transition: transform 0.1s, box-shadow 0.1s;
            transform: scale(1);
            z-index: 1;

        
            .app-card-icon{
                font-size: 30px;
            }
        
            .app-card-title{
                font-size: 20px;
                height: 65px;
            }
        
            .app-card-text{
                display:flex;
                flex-direction: column;
                gap: 8px;
                padding-bottom: 15px;
        
                p {
                    margin-top: 0;
                    margin-bottom: 1em;
                }
            }
        
            .app-card-arrow{
                position: relative;
            }
        
            .blue{
                color: rgb(60, 60, 185)
            }
            .red{
                color: rgb(192, 57, 57)
            }
            .green{
                color: rgb(202, 143, 54)
            }
            .orange{
                color: rgb(48, 175, 116)
            }
    
            &:hover{
                transform: scale(1.02);
                box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);  
                transition: transform 0.2s, box-shadow 0.2s;
                z-index: 1;
                color: black;
            }
        }
    }
}