@import '../../Variables.scss';

.filtered-documents{
    padding: 15px;
    min-height: 300px;
    p{
        margin: 0;
    }
    .go-back-btn{
        align-self: flex-start;
    }
    .filtered-documents-img{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: calc(100vh - 245px - 47px - 15px);
        p{
            text-align: center;
            width: 300px;
            font-size: 15px;
            font-weight: 300;
        }
        img{
            width: 300px;
        }
    }
    .filtered-documents-list{
        margin: auto;
        padding: 40px;
        max-width: 1500px;
        .filtered-documents-columns{
            display: flex;
            justify-content: flex-end;
            gap: 45px;
            margin-right: 20px;
            font-size: 13px;
            color: $qualitas-dark-grey;
        }
        .filtered-file-data{
            display: flex;
            gap: 15px;
            p{
                width: 85px;
                text-align: end;
            }
        }

        .filtered-documents-item{
            cursor: pointer;
            &:hover{
                background-color: rgb(245, 245, 245);
            }
        }
    
        .filtered-documents-input{
            display: flex;
            width: 300px;
            border-bottom: 1px solid $qualitas-dark-grey;
            margin-left: 20px;
            input{
                border: none;
                width: 300px;
                padding: 0px 0px 5px 0px;
                &::placeholder{
                    color: $qualitas-grey;
                    font-size: 13px;
                    letter-spacing: 1px;
                }
                &:focus{
                    outline: none;
                }
            }
        }
    }
}