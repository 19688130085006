@import '../../Variables.scss';

.documents-viewer {
	.files-viewer {
		min-height: 100vh;

		.data-room-tree-container{
			background-color: $qualitas-light-grey;
		}
		.ant-tree {
			background-color: inherit;
		}

		.document-viewer {
			height: 1300px;

			object {
				padding-bottom: 2%;
			}
		}

		.document-viewer-empty {
			.ant-empty {
				display: none;
			}
		}
	}
}